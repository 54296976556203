import axios from 'axios'

const axiosInstance = axios.create({
    // Central server call - unable to test local API changes
    baseURL: 'https://finder.smartprojectconsulting.hu',

    // localhost:8000 - requires 'python manage.py runserver'
    //baseURL: '',

    timeout: 10000,
    headers: {
        'Authorization': localStorage.getItem('access_token') ? "JWT " + localStorage.getItem('access_token') : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
})

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;

        // test for token presence, no point in sending a request if token isn't present
        if (localStorage.getItem('refresh_token') && error.response.status === 401 && error.response.statusText === "Unauthorized") {
            //Stop interceptor if the requested API call is refresh token - prevents infinite loop without using more interceptors
            if(error.response.config.url.toString().toUpperCase().includes("/api/token-refresh/".toUpperCase())) {
                //Remove tokens from cookies
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                window.location.reload()
            } else {
                const refresh_token = localStorage.getItem('refresh_token')
                return axiosInstance
                    .post('/api/token-refresh/', { refresh: refresh_token })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access)
                        localStorage.setItem('refresh_token', response.data.refresh)

                        axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access
                        originalRequest.headers['Authorization'] = "JWT " + response.data.access

                        return axiosInstance(originalRequest)
                    })
                    .catch(err => {
                        console.log(err)
                    });
            }
        }
        // specific error handling done elsewhere
        return Promise.reject({ ...error });
    }
)

export default axiosInstance