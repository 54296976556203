import React from 'react'
import axiosInstance from '../axiosApi/axiosApi'
import { DirectionsRenderer, DirectionsService, GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import Grid from "@material-ui/core/Grid"
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import { Grow, Paper } from '@material-ui/core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './MyMapComponent.module.css'

const containerStyle = {
    width: '100%',
    height: '400px'
}

const locatorOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 10000
}

const sliderMarks = [
    { id: 1, value: 10, label: '10 km' },
    { id: 2, value: 20, label: '20 km' },
    { id: 3, value: 30, label: '30 km' },
    { id: 4, value: 40, label: '40 km' },
    { id: 5, value: 50, label: '50 km' }
]

var id, successfulSearchCallsCount = 0, unsuccessfulSearchCallsCount = 0

export default class MyMapComponent extends React.Component {
    typingTimer = null
    constructor(props) {
        super(props)
        this.state = {
            destination: null,
            destinationOpener: false,
            directions: null,
            searchingRadius: 50,
            enable_route_planning: false,
            infoWindowTab: "basic",
            origin: { lat: null,  lng: null },
            places_response: null,
            position_history: [],
            results: [],
            search: "",
            traveltime_response: { distance: null, duration: null, distance_value: null, duration_value: null, place_id: null },

            log: "",
        }
    }

    calculateBeeLineDistance = (place) => {
        if (this.state.destination)
            if(place.place_id === this.state.traveltime_response.place_id)
                return this.state.traveltime_response.distance_value !== null ? (this.state.traveltime_response.distance_value / 1000).toFixed(1) : ""

        var R = 6371
        var dLat = (place.geometry.location.lat - this.state.origin.lat) * Math.PI / 180
        var dLon = (place.geometry.location.lng - this.state.origin.lng) * Math.PI / 180
        var a = 0.5 - Math.cos(dLat) / 2 + Math.cos(place.geometry.location.lat * Math.PI / 180) *
            Math.cos(this.state.origin.lat * Math.PI / 180) * (1 - Math.cos(dLon)) / 2
        return Math.round(R * 2 * Math.asin(Math.sqrt(a)) * 100) / 100
    }

    componentDidMount = () => {
        if(navigator.geolocation) {
            id = navigator.geolocation.watchPosition(this.setPosition, this.displayError, locatorOptions)
        }
        else {
            this.setState({ log: "Geolocation is not supported by this browser." })
        }
    }

    componentWillUnmount = () => {
        navigator.geolocation.clearWatch(id)
        clearTimeout(this.typingTimer)
    }

    displayError = (error) => {
        switch(error.code) {
            case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.")
                this.setState({ log: "User denied the request for Geolocation." })
                break
            case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.")
                this.setState({ log:"Location information is unavailable." })
                break
            case error.TIMEOUT:
                console.log("The request to get user location timed out.")
                this.setState({ log:"The request to get user location timed out." })
                break
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.")
                this.setState({ log:"An unknown error occurred." })
                break
            default:
                break
        }
    }

    getPlaceDetails = (placeID) => {
        axiosInstance.post('/api/getPlaceDetails/', {
            place_id: placeID
        }).then((response) => {
            if(response) {
                if (response.status === 200) {
                    response = JSON.parse(response.data)

                    //Find stored place add opening hours info
                    var copy = {...this.state.destination}
                    if(copy.place_id === placeID) {
                        copy['opening_hours_'] = response.result.opening_hours.weekday_text
                        this.setState({ destination: copy })
                    }
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    getTravelTime = (destinationPosition) => {
        //destinationPosition.lat, lng comes from Google API => place.geometry.location.lat, lng
        axiosInstance.post('/api/getTravelTime/', {
            origin_latitude: this.state.origin.lat,
            origin_longitude: this.state.origin.lng,
            destination_latitude: destinationPosition.lat,
            destination_longitude: destinationPosition.lng
        }).then((response) => {
            if(response) {
                if (response.status === 200) {
                    response = JSON.parse(response.data)
                    /*console.log("getTravelTime RESULTS: ", response.rows[0].elements[0])*/
                    if(response.rows)
                        if(response.rows[0].elements) {
                            return this.setState({
                                traveltime_response: { 
                                    distance: "Distance: " + response.rows[0].elements[0].distance.text,
                                    duration: "Estimated travel time: " + response.rows[0].elements[0].duration.text,
                                    distance_value: response.rows[0].elements[0].distance.value,
                                    duration_value: response.rows[0].elements[0].duration.value,
                                    place_id: this.state.destination.place_id
                                }
                            })   
                        }
                    return this.setState({ traveltime_response: { 
                        distance: "Travel time could not be estimated!",
                        duration: ""
                    }})
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    setPosition = (position) => {
        //position comes from HTML locator => navigator.geolocation.watchPosition()
        var copy = [...this.state.position_history]
        copy.push([position.coords.latitude, position.coords.longitude])
        this.setState({
            origin: { lat: position.coords.latitude, lng: position.coords.longitude }, 
            position_history: copy
        })
        window.localStorage.setItem("coordinates", JSON.stringify(copy))
    }

    nearbySearch = (keyword = undefined, nextPageToken = undefined) => {
        axiosInstance.post('/api/getPlaces/', {
            radius: (this.state.searchingRadius * 1000).toString(),
            keyword: keyword,
            latitude: this.state.origin.lat,
            longitude: this.state.origin.lng,
            next_page_token: nextPageToken
        }).then((response) => {
            if(response) {
                if (response.status === 200) {
                    response = JSON.parse(response.data)
                    if((response.next_page_token || response.status === "INVALID_REQUEST") && successfulSearchCallsCount < 3 && unsuccessfulSearchCallsCount < 10) {
                        setTimeout(() => {
                            this.nearbySearch(undefined, response.next_page_token)
                        }, 1000)
                    }
                    if(response.status === "OK") {
                        successfulSearchCallsCount += 1
                        response.results.forEach((place) => {
                            place['direct_distance'] = this.calculateBeeLineDistance(place)
                        })
                        response.results.sort((a, b) => (a.direct_distance > b.direct_distance) ? 1 : -1)
                        var copy = [...this.state.results]
                        copy.push(...response.results)
                        /*console.log(successfulSearchCallsCount)*/
                        this.setState({ results: copy }, /*() => console.log("nearbySearch RESULTS: ", this.state.results)*/)
                    }
                    else {
                        unsuccessfulSearchCallsCount += 1
                        /*console.log("Fail counter", unsuccessfulSearchCallsCount)*/
                    }
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    onDestinationRoute = (place) => {
        this.setState({ destination: place, enable_route_planning: true }, () => {
            this.getPlaceDetails(place.place_id)
            this.getTravelTime({ lat: place.geometry.location.lat, lng: place.geometry.location.lng })
        })
    }

    infoWindowContent = () => {
        if(this.state.infoWindowTab === "basic")
            return(
                <div>
                    <h4>{this.state.destination.name}</h4>
                    <p>
                        {this.state.traveltime_response.distance}<br />
                        {this.state.traveltime_response.duration}
                    </p>
                    <Button variant="contained" color="secondary" onClick={() => this.setState({ infoWindowTab: "opening-hours" })}>Opening hours</Button>
                </div>
            )
        else if(this.state.infoWindowTab === "opening-hours")
            return(
                <div>
                    <h4>Opening hours</h4>
                    <div>
                        {this.state.destination.opening_hours_ &&
                            <React.Fragment>
                                {this.state.destination.opening_hours_.map((day, index) => (
                                    <p key={index}>{day}</p>
                                ))}
                            </React.Fragment>
                        }
                    </div>
                    <Button variant="contained" color="secondary" onClick={() => this.setState({ infoWindowTab: "basic" })}>Back</Button>
                </div>
            )
    }

    mapRender = () => {
        return(
            <LoadScript
                googleMapsApiKey="AIzaSyBXeK1fKH6lNjm1zvdK1K3Dp_sy1Xzar5Y"
            >
                <GoogleMap
                    center={ this.state.destination ? this.state.destination.geometry.location : this.state.origin}
                    mapContainerStyle={containerStyle}
                    zoom={13}
                >
                    {this.state.destination &&
                        <DirectionsService
                            options={{
                            destination: this.state.destination.geometry.location,
                            origin: this.state.origin,
                            travelMode: 'DRIVING'
                            }}
                            callback={this.directionsCallback}
                        />
                    }

                    {this.state.destination && 
                        <InfoWindow position={this.state.destination.geometry.location}>
                            {this.infoWindowContent()}
                        </InfoWindow>
                    }

                    <Marker
                        position={this.state.origin}
                        key={"self-position"}
                    >
                    </Marker>

                    {this.state.results.map((place, index) => (
                        <React.Fragment>
                            {index < 3 ? (
                                <Marker
                                    key={"placeid_" + place.place_id}
                                    icon={{
                                        path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                                        fillColor: '#00FF00',
                                        fillOpacity: 1,
                                        strokeColor: '#000',
                                        strokeWeight: 1,
                                        scale: 1, 
                                    }}
                                    position={{ lat: place.geometry.location.lat, lng: place.geometry.location.lng }}
                                    onClick={() => {
                                        this.onDestinationRoute(place)
                                    }}
                                />
                                ):(
                                <Marker
                                    key={"placeid_" + place.place_id}
                                    position={{ lat: place.geometry.location.lat, lng: place.geometry.location.lng }}
                                    onClick={() => {
                                        this.onDestinationRoute(place)
                                    }}
                                />
                            )}
                        </React.Fragment>
                        )
                    )}

                    {this.state.directions &&
                        <DirectionsRenderer
                            options={{ directions: this.state.directions }}
                        >
                        </DirectionsRenderer>
                    }

                </GoogleMap>
            </LoadScript>
        )
    }

    directionsCallback = (response) => {
        if(this.state.enable_route_planning) {
            /*console.log("directionsCallback", response)*/
            this.setState({ directions: response, enable_route_planning: false })
        }
    }

    openGoogleMaps = () => {
        const url = 'https://maps.google.com/?q=' + this.state.destination.geometry.location.lat + "," + this.state.destination.geometry.location.lng
        window.open(url, '_blank');
    }

    openWaze = () => {
        const url = 'https://waze.com/ul?ll=' + this.state.destination.geometry.location.lat + '%2C' + this.state.destination.geometry.location.lng + '&navigate=yes'
        window.open(url, '_blank')
    }

    handleTextFieldChange = (evt) => {
        const val = evt.target.value
        this.setState({
            results: [],
            search: val
        })
        clearTimeout(this.typingTimer)
        this.typingTimer = setTimeout(() => {
            if (val) {
                if(val.length > 2) this.nearbySearch(val)
            }
        }, 500)
    }

    render() {
        return(
            (this.state.origin.lat != null && this.state.origin.lng != null) ?
                <React.Fragment>
                    <Grid container justifyContent="center" align="center" className="hideOverflow">
                        <Grid container item justifyContent="center">
                            <Grid item xs={12} md={12} align="center" className={styles.padding20}>
                                {this.mapRender()}
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent="center" className={"flex_vertical_center"}>
                            <Grid item xs={10} md={4}>
                                <Slider
                                    aria-label="Keresési sugár"
                                    defaultValue={30}
                                    onChangeCommitted={(event, value) => {
                                        this.setState({ searchingRadius: value })
                                    }}
                                    valueLabelDisplay="auto"
                                    step={5}
                                    marks={sliderMarks}
                                    min={10}
                                    max={50}
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={1} container item justifyContent="center" className={"flex_vertical_center pb-20"}>
                            <Grid item xs={10} md={4} align="center">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({ results: [] })
                                        successfulSearchCallsCount = 0
                                        unsuccessfulSearchCallsCount = 0
                                        this.nearbySearch()
                                    }}
                                    fullWidth={true}
                                >
                                    Find shops
                                </Button>
                            </Grid>
                        </Grid>
                        {this.state.destination &&
                            <React.Fragment>
                                {false && 
                                    <Grid spacing={1} container item justifyContent="center" className={"flex_vertical_center pb-20"}>
                                        <Grid item xs={6} md={2} align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.openGoogleMaps()}
                                                fullWidth={true}
                                            >
                                                Open in Google Maps
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} md={2} align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.openWaze()}
                                                fullWidth={true}
                                            >
                                                Open in Waze
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid spacing={1} container item justifyContent="center" className={"flex_vertical_center pb-20"}>
                                    <Grid item xs={10} md={4} align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.setState({ destinationOpener: true })}
                                        fullWidth={true}
                                    >
                                        Open route via...
                                    </Button>

                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid container item justifyContent="center" className={"flex_vertical_center"}>
                            <Grid item xs={10} md={4}>
                                <TextField
                                    type="text"
                                    variant="standard"
                                    label= {"Find something else"}
                                    name="search"
                                    value={this.state.search}
                                    onChange={this.handleTextFieldChange}
                                    inputProps={{
                                        className: "black"
                                    }}
                                    InputLabelProps={{
                                        className: "black"
                                    }}
                                    autoComplete='off'
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent="center">
                            <Grid item xs={10} md={5} align="center" className={styles.shopList}>
                                <List dense className={styles.listStyle}>
                                {this.state.results.map((place, index) => (
                                    <ListItem
                                        button
                                        key={"listitem_" + place.place_id}
                                        onClick={() => this.onDestinationRoute(place)}
                                    >
                                        <Grid container item>
                                            <Grid item xs={2} md={2}>
                                                <Avatar
                                                    alt={place.name}
                                                    src={place.icon}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4}>
                                                <div className="flex_vertical_center">
                                                    <ListItemText id={place.place_id} primary={place.name} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <ListItemText
                                                    id={place.place_id}
                                                    primary={place.vicinity}
                                                />
                                                <b>({this.calculateBeeLineDistance(place) + " km)"}</b>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>

                    {this.state.destinationOpener &&
                        <div className="fullscreen-overlay dim-background"></div>
                    }
                    <Grow in={this.state.destinationOpener} mountOnEnter unmountOnExit>
                        <Grid container align="center" className="fullscreen-overlay flex-center">
                            <Grid item xs={10} md={6}>
                                <Paper elevation={4} className="full-width relative">
                                    <div className="popup-dismiss pressable" onClick={() => this.setState({ destinationOpener: false })}>
                                        <FontAwesomeIcon icon={faTimes} className="fa-sm" />
                                    </div>
                                    <Grid container className={styles.padding10}>
                                        <Grid container item justifyContent="center" className={styles.paddingBottom10}>
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => this.openGoogleMaps()}
                                                    fullWidth={true}
                                                >
                                                    Google Maps
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container item justifyContent="center">
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => this.openWaze()}
                                                    fullWidth={true}
                                                >
                                                    Waze
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grow>
                </React.Fragment> :
                <React.Fragment>
                    <h3 className={styles.textCenter}>Unable to locate device!</h3>
                    <p className={styles.textCenter}>{this.state.log}</p>
                </React.Fragment>
        )
    }
}